import React from 'react';

import Layout from '../../../components/layout';
import Container from '../../../components/container';
import ResidenceDetails from '../../../components/residence-details';

import Real1 from '../../../images/work/03_PB_ALDRICH/PHOTOS/AL_01.jpg';
import Real2 from '../../../images/work/03_PB_ALDRICH/PHOTOS/AL_02.jpg';
import Real3 from '../../../images/work/03_PB_ALDRICH/PHOTOS/AL_03.jpg';
import Real4 from '../../../images/work/03_PB_ALDRICH/PHOTOS/AL_04.jpg';
import Real5 from '../../../images/work/03_PB_ALDRICH/PHOTOS/AL_05.jpg';
import Real6 from '../../../images/work/03_PB_ALDRICH/PHOTOS/AL_06.jpg';
import Real7 from '../../../images/work/03_PB_ALDRICH/PHOTOS/AL_07.jpg';
import Real8 from '../../../images/work/03_PB_ALDRICH/PHOTOS/AL_08.jpg';
import Real9 from '../../../images/work/03_PB_ALDRICH/PHOTOS/AL_09.jpg';
import Real10 from '../../../images/work/03_PB_ALDRICH/PHOTOS/AL_10.jpg';
import Real11 from '../../../images/work/03_PB_ALDRICH/PHOTOS/AL_11.jpg';
import Real12 from '../../../images/work/03_PB_ALDRICH/PHOTOS/AL_12.jpg';
import Real13 from '../../../images/work/03_PB_ALDRICH/PHOTOS/AL_13.jpg';
import Real1s from '../../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_01.jpg';
import Real2s from '../../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_02.jpg';
import Real3s from '../../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_03.jpg';
import Real4s from '../../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_04.jpg';
import Real5s from '../../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_05.jpg';
import Real6s from '../../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_06.jpg';
import Real7s from '../../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_07.jpg';
import Real8s from '../../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_08.jpg';
import Real9s from '../../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_09.jpg';
import Real10s from '../../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_10.jpg';
import Real11s from '../../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_11.jpg';
import Real12s from '../../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_12.jpg';
import Real13s from '../../../images/work/03_PB_ALDRICH/PHOTOS/450X280/AL-SM_13.jpg';
// import Process1 from '../../../images/work/03_PB_ALDRICH/PROCESS/ALD_01.jpg'
// import Process2 from '../../../images/work/03_PB_ALDRICH/PROCESS/ALD_02.jpg'
// import Process3 from '../../../images/work/03_PB_ALDRICH/PROCESS/ALD_03.jpg'
// import Process4 from '../../../images/work/03_PB_ALDRICH/PROCESS/ALD_04.jpg'
// import Process5 from '../../../images/work/03_PB_ALDRICH/PROCESS/ALD_05.jpg'
// import Process6 from '../../../images/work/03_PB_ALDRICH/PROCESS/ALD_06.jpg'
// import Process7 from '../../../images/work/03_PB_ALDRICH/PROCESS/ALD_07.jpg'
// import Process8 from '../../../images/work/03_PB_ALDRICH/PROCESS/ALD_08.jpg'
// import Process1s from '../../../images/work/03_PB_ALDRICH/PROCESS/450X280/ALD-SM_01.jpg'
// import Process2s from '../../../images/work/03_PB_ALDRICH/PROCESS/450X280/ALD-SM_02.jpg'
// import Process3s from '../../../images/work/03_PB_ALDRICH/PROCESS/450X280/ALD-SM_03.jpg'
// import Process4s from '../../../images/work/03_PB_ALDRICH/PROCESS/450X280/ALD-SM_04.jpg'
// import Process5s from '../../../images/work/03_PB_ALDRICH/PROCESS/450X280/ALD-SM_05.jpg'
// import Process6s from '../../../images/work/03_PB_ALDRICH/PROCESS/450X280/ALD-SM_06.jpg'
// import Process7s from '../../../images/work/03_PB_ALDRICH/PROCESS/450X280/ALD-SM_07.jpg'
// import Process8s from '../../../images/work/03_PB_ALDRICH/PROCESS/450X280/ALD-SM_08.jpg'

const Residence03 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="THE ALDRICH RESIDENCE"
        residenceLocation="PEBBLE BEACH, CALIFORNIA"
        infoList={[
          'Remodel of existing 5,230 SF single family residence. Addition of 3,095 SF of floor area (including new 780 SF Caretaker Unit above garage) to a total of 8,325 SF. New 250 SF gazebo with outdoor fireplace.',
          'Design - 2011-2012',
          'Construction - 2013-2015',
          'Architect of record: International Design Group, Inc',
          'General Contractor: Wallis Construction, Inc',
          'Structural Engineer: BCA Structural Engineering, Inc',
        ]}
        testimonialList={[
          'Very early in the design process of our home in Pebble Beach, due to the illness of John Matthams, we were extremely fortunate to have Anatoly Ostretsov step in and take charge of the architecture, design and specifications for our project.  His quick and creative talent exceeded our expectations turning our home into an estate.',
          'We found Anatoly to be knowledgeable, cooperative, innovative and highly professional.  Anatoly always produced positive results.  Stepping in to fill the shoes of renowned designer John Matthams could easily have become very stressful, however Anatoly made the transition seamless and pleasant.  He gave us the piece of mind that we were in excellent hands.  He is an architect whose experience, patience, quality of service and great listening skills led to a superb design.  With Anatoly at the helm everyone was motivated to put their best foot forward for the common good of making a dream home everything we imagined.  His ideas, feedback, comments, attention to details, suggestions and his quick on-the-spot renderings with his “red pencil” brought clarity to our ideas.  He had a deep understanding of our priorities and acted professionally and responsively in all matters including coordinating with the contractor and sub-contractors, engineers and building inspectors.  In short, we give Anatoly an “excellent” rating as an architect.  Without hesitation, we highly recommend Anatoly Ostretsov if you are looking for the services of an architect.',
          'Richard and Joan Aldrich',
          'Pebble Beach, California',
        ]}
        photos={[
          Real1,
          Real2,
          Real3,
          Real4,
          Real5,
          Real6,
          Real7,
          Real8,
          Real9,
          Real10,
          Real11,
          Real12,
          Real13,
        ]}
        photosSmall={[
          Real1s,
          Real2s,
          Real3s,
          Real4s,
          Real5s,
          Real6s,
          Real7s,
          Real8s,
          Real9s,
          Real10s,
          Real11s,
          Real12s,
          Real13s,
        ]}
        processPhotos={[]}
        processPhotosSmall={[]}
      />
    </Container>
  </Layout>
);

export default Residence03;
